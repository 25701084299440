import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import fetchWrapper from "@mobilemind/common/src/functions/fetchWrapper"
import qs from "qs"
import moment from "moment"
import debounceThunk from "@mobilemind/common/src/functions/debounceThunk"

import _ from "lodash"
import { updateEventField } from "../../../functions"

export const fetchConferenceRoles = createAsyncThunk(
  "activeConferenceSlice/fetchConferenceRoles",
  async () => {
    let response = await fetchWrapper.get("/api/taxonomy_term/event_role")
    if (response.ok) {
      let data = await response.json()
      return data.data
    }
  }
)

export const fetchConferenceFeedbackSummary = createAsyncThunk(
  "activeConferenceSlice/fetchConferenceFeedbackSummary",
  async (args, thunkAPI) => {
    const { activeConference } = thunkAPI.getState()

    const response = await fetchWrapper.get(
      "/api/event_feedback/" + activeConference.drupal_internal__id
    )

    if (response.ok) {
      const data = await response.json()
      return data.data
    }
  }
)

export const fetchActiveConference = createAsyncThunk(
  "activeConferenceSlice/fetchActiveConference",
  async (args, thunkAPI) => {
    const { id } = args
    const { session } = thunkAPI.getState()

    let event = {}
    let category
    let query = {
      filter: {
        drupal_internal__id: id,
      },
      include:
        "field_tags,field_location,field_personnel,field_event_image,field_attachment,field_current_editor,field_room,field_event_category,field_event",
    }

    let response = await fetchWrapper.get(
      "/api/mobilemind_event_entity/conference?" + qs.stringify(query)
    )

    if (response.ok) {
      let data = await response.json()

      const currentEditor =
        data.included &&
        data.included.filter((included) => included.type === "user--user")

      // Get all locations for the conference
      let locations = data.included
        ? [
            ...data.included
              .filter(
                (included) =>
                  included.type === "mobile_mind_location--location_base"
              )
              .map((location) => {
                location.rooms = []
                return location
              }),
          ]
        : []

      let personnel =
        data.included &&
        data.included.filter(
          (included) =>
            included.type === "mm_event_personnel--mm_event_personnel"
        )

      category =
        data.included &&
        data.included.filter(
          (included) => included.type === "taxonomy_term--category"
        )

      // Get ready for add on
      let addOn
      const include = [
        "field_badge",
        "field_prereq_courses",
        "field_prereq_learning_path",
        "field_participants_group",
        "field_participants_users",
        "field_participants_job_title",
        "field_feedback_form",
        "field_replacement_courses",
      ]

      query = {
        include: include.join(","),
      }

      let addOnResponse = await fetchWrapper.get(
        "/api/mobilemind_event_addon/event_addon_base/" +
          data.data[0].relationships.field_addon.data.id +
          "?" +
          qs.stringify(query)
      )

      if (addOnResponse.ok) {
        addOn = await addOnResponse.json()

        const subGroups = thunkAPI.getState().session.subGroups.data
        let participantSubGroups = []

        addOn.data.relationships.field_participants_group.data.forEach(
          (participant) => {
            if (participant.type === "group--group") {
              let targetSubGroup = subGroups.find(
                (group) =>
                  group.relationships.entity_id.data.id === participant.id
              )
              participantSubGroups.push(targetSubGroup)
            }
          }
        )

        addOn.data.subGroups = participantSubGroups
      }

      let log,
        logId =
          addOn.data.relationships.field_attendance_log.data &&
          addOn.data.relationships.field_attendance_log.data.id

      event = data
      event.category = category && category[0]
      event.feedbackForm =
        addOn.included &&
        addOn.included.find(
          (included) => included.type === "mm_form--event_feedback"
        )

      if (logId) {
        let logResponse = await fetchWrapper.get(
          "/api/mm_attendance_log/attendance_log_base/" + logId
        )
        if (logResponse.ok) {
          log = await logResponse.json()
        }
      }

      let badge =
        addOn.included &&
        addOn.included.find(
          (included) => included.type === "badges_entity--badges_entity"
        )
      let badgeData

      if (badge) {
        let badgeResponse = await fetchWrapper.get(
          "/api/badges_entity/badges_entity/" +
            badge.id +
            "?" +
            qs.stringify({ include: "field_badge_image" })
        )
        if (badgeResponse.ok) {
          badgeData = await badgeResponse.json()
        }
      }

      if (badge && badgeData) {
        event.badgeAwarded = badge &&
          badgeData && {
            id: badgeData.data.id,
            name: badgeData.data.attributes.name,
            image: badgeData.included[0],
          }
      }

      if (!window.location.href.includes("view")) {
        if (!data.data[0].attributes.field_editor_lock_time) {
          let lockBody = {
            data: {
              id: event.data[0].id,
              type: event.data[0].type,
              attributes: {
                field_editor_lock_time: moment().format(),
              },
              relationships: {
                field_current_editor: {
                  data: {
                    type: "user--user",
                    id: session.user.id,
                  },
                },
              },
            },
          }

          fetchWrapper.patch(
            "/api/mobilemind_event_entity/conference/" + data.data[0].id,
            session.token,
            JSON.stringify(lockBody)
          )
        }
      }
      event.attendanceLog = log
      event.addOn = addOn
      event.currentEditor = currentEditor
      event.locations = locations
      event.personnel = personnel

      return event
    }
  }
)

export const fetchSessionsForConference = createAsyncThunk(
  "activeConferenceSlice/fetchSessionsForConference",
  async (args, thunkAPI) => {
    const { id } = args
    const { session } = thunkAPI.getState()

    const sessionResponse = await fetchWrapper.get(
      "/api/hq-conf-calendar/" + id + "/" + session.group.id[0].value
    )

    let sessions = await sessionResponse.json()
    return sessions
  }
)

export const fetchConferenceMessages = createAsyncThunk(
  "activeConferenceSlice/fetchConferenceMessages",
  async (args, thunkAPI) => {
    let query = {
      filter: {
        "field_event.id": thunkAPI.getState().activeConference.id,
      },
      include: "user_id,field_attachment",
    }

    let response = await fetchWrapper.get(
      "/api/mm_event_message/event_message_base?" + qs.stringify(query)
    )
    if (response.ok) {
      let data = await response.json()
      const users = data.included
        ? data.included.filter((included) => included.type === "user--user")
        : []

      data.data.forEach((message) => {
        message.user = users.find(
          (user) =>
            message.relationships.user_id.data &&
            user.id === message.relationships.user_id.data.id
        )
      })

      return data.data
    }
  }
)

const searchSessionsForImport = createAsyncThunk(
  "activeConferenceSlice/searchSessionsForImport",
  async (args, thunkAPI) => {
    const query = {
      search: args,
    }

    const { session } = thunkAPI.getState()

    let response = await fetchWrapper.get(
      "/api/conference-session-import-search/" +
        session.group.id[0].value +
        "?" +
        qs.stringify(query)
    )

    if (response.ok) {
      const data = await response.json()
      return data.rows
    }
  }
)

export const debouncedSearchSessionsForImport = debounceThunk(
  searchSessionsForImport,
  750
)
export const updateSessionImportSearch = createAsyncThunk(
  "activeConferenceSlice/updateSessionImportSearch",
  async (args, thunkAPI) => {
    thunkAPI.dispatch(debouncedSearchSessionsForImport(args))

    return args
  }
)

const getCourses = createAsyncThunk(
  "activeConferenceSlice/getCourses",
  async (args) => {
    let response = await fetchWrapper.get(
      "/api/course_entity/explore?search=" + args.value
    )

    if (response.ok) {
      let data = await response.json()
      let courses = []

      data.data.forEach((course) => {
        if (!courses.find((existing) => existing.id === course.id)) {
          courses.push(course)
        }
      })

      return { courses, args }
    }
  }
)

const debouncedGetCourses = debounceThunk(getCourses, 750)
export const updateConferenceCourseSearch = createAsyncThunk(
  "activeConferenceSlice/updateConferenceCourseSearch",
  async (args, thunkAPI) => {
    thunkAPI.dispatch(debouncedGetCourses(args))

    return args
  }
)

const getLearningPaths = createAsyncThunk(
  "activeConferenceSlice/getLearningPaths",
  async (search) => {
    let response = await fetchWrapper.get(
      "/api/learning_path/explore?search=" + search
    )
    if (response.ok) {
      let data = await response.json()
      return data
    }
  }
)

const debouncedGetLearningPaths = debounceThunk(getLearningPaths, 750)
export const updateConferenceLPSearch = createAsyncThunk(
  "activeConferenceSlice/updateConferenceLPSearch",
  async (search, thunkAPI) => {
    thunkAPI.dispatch(debouncedGetLearningPaths(search))

    return search
  }
)

export const getConferenceUserList = createAsyncThunk(
  "activeConferenceSlice/getConferenceUserList",
  async (args, thunkAPI) => {
    const { session, activeConference } = thunkAPI.getState()

    const isOrgAdmin =
      session.orgRoles.includes("organization-admin") ||
      session.orgRoles.includes("organization-scheduler")
    const orgId = session.group.id[0].value
    const groupId =
      session.subgroup && session.subgroup.id && session.subgroup.id[0].value

    let id = isOrgAdmin ? orgId : groupId

    let query = {}

    if (activeConference.learnerSearch) {
      query.search = activeConference.learnerSearch
    }

    query["role[0]"] = "all"
    let response = await fetchWrapper.get(
      "/api/organization-members/" + id + "?" + qs.stringify(query)
    )

    if (response.ok) {
      let data = await response.json()

      if (data.rows.content) {
        data.rows = []
      } else {
        data.rows.forEach((user) => {
          user.id = user.uuid
        })
      }
      if (!activeConference.learnerSearch) {
        return {
          rows: [],
        }
      }

      return data
    }
  }
)

export const getConferenceUserEvents = createAsyncThunk(
  "activeConferenceSlice/getConferenceUserEvents",
  async (args, thunkAPI) => {
    const { activeConference, session } = thunkAPI.getState()
    const subGroups = session.subGroups.data

    let eventId =
      args && args.eventId
        ? args.eventId
        : thunkAPI.getState().activeConference.drupal_internal__id

    let query = {
      search: activeConference.inviteeSearch,
      page: activeConference.inviteesCurrentPage,
    }
    if (activeConference.filterRSVP !== "any") {
      query.field_rsvp_value = activeConference.filterRSVP
    }
    if (activeConference.filterCheckedIn !== "any") {
      query.field_attended_value = activeConference.filterCheckedIn
    }
    if (activeConference.filterGroup !== "all") {
      const activeGroup = subGroups.find(
        (group) => group.entity_id === activeConference.filterGroup
      )
      query.field_subgroup_value = activeGroup.attributes.label
    }

    query.sort_by = activeConference.sortBy
    query.sort_order = activeConference.sortOrder

    let response = await fetchWrapper.get(
      "/api/user-events/" +
        eventId +
        "/" +
        session.group.id[0].value +
        "?" +
        qs.stringify(query)
    )

    if (response.ok) {
      let data = await response.json()

      return data
    }
  }
)

export const getAttendanceSummary = createAsyncThunk(
  "activeConferenceSlice/getAttendanceSummary",
  async (args, thunkAPI) => {
    const { activeConference } = thunkAPI.getState()

    const { filters } = activeConference.attendanceSummary
    const { sortBy, sortOrder, search } = filters

    let customSortOrder = sortOrder
    if (sortBy === "name") {
      customSortOrder = sortOrder === "DESC" ? "ASC" : "DESC"
    }

    let query = {
      search: search,
      sort_order: customSortOrder,
      sort_by: sortBy,
      page: {
        offset: activeConference.attendanceSummary.currentPage * 25,
      },
    }

    let response = await fetchWrapper.get(
      "/api/conference_attendance/" +
        activeConference.drupal_internal__id +
        "?" +
        qs.stringify(query)
    )

    if (response.ok) {
      let data = await response.json()

      return data
    }
  }
)

const debouncedGetAttendanceSummary = debounceThunk(getAttendanceSummary, 750)

export const updateSummaryFilters = createAsyncThunk(
  "activeConferenceSlice/updateSummaryFilters",
  async (args, thunkAPI) => {
    thunkAPI.dispatch(debouncedGetAttendanceSummary())
    return args
  }
)

export const increaseSummaryPage = createAsyncThunk(
  "activeConferenceSlice/increaseSummaryPage",
  async (args, thunkAPI) => {
    thunkAPI.dispatch(debouncedGetAttendanceSummary())
    return args
  }
)

const debouncedGetUserEvents = debounceThunk(getConferenceUserEvents, 750)

export const updateConferenceInviteeSearch = createAsyncThunk(
  "activeConferenceSlice/updateConferenceInviteeSearch",
  async (args, thunkAPI) => {
    thunkAPI.dispatch(debouncedGetUserEvents())
    return args
  }
)

const debouncedGetConferenceUserList = debounceThunk(getConferenceUserList, 750)

export const updateConferenceUserSearch = createAsyncThunk(
  "activeConferenceSlice/updateConferenceUserSearch",
  async (args, thunkAPI) => {
    thunkAPI.dispatch(debouncedGetConferenceUserList())
    return args
  }
)

export const increaseConferenceInviteePage = createAsyncThunk(
  "activeConferenceSlice/increaseConferenceInviteePage",
  async (args, thunkAPI) => {
    thunkAPI.dispatch(debouncedGetUserEvents())
    return args
  }
)

export const updateConferenceInviteeRSVPFilter = createAsyncThunk(
  "activeConferenceSlice/updateInviteeRSVPFilter",
  async (args, thunkAPI) => {
    thunkAPI.dispatch(debouncedGetUserEvents())
    return args
  }
)

export const updateConferenceSortBy = createAsyncThunk(
  "activeConferenceSlice/updateConferenceSortBy",
  async (args, thunkAPI) => {
    thunkAPI.dispatch(debouncedGetUserEvents())
    return args
  }
)

export const updateConferenceSortOrder = createAsyncThunk(
  "activeConferenceSlice/updateConferenceSortOrder",
  async (args, thunkAPI) => {
    thunkAPI.dispatch(debouncedGetUserEvents())
    return args
  }
)

export const updateConferenceCheckedInFilter = createAsyncThunk(
  "activeConferenceSlice/updateConferenceCheckedInFilter",
  async (args, thunkAPI) => {
    thunkAPI.dispatch(debouncedGetUserEvents())
    return args
  }
)

export const updateConferenceGroupFilter = createAsyncThunk(
  "activeConferenceSlice/updateConferenceGroupFilter",
  async (args, thunkAPI) => {
    thunkAPI.dispatch(debouncedGetUserEvents())
    return args
  }
)

export const getRoomsForConferenceLocation = createAsyncThunk(
  "activeConferenceSlice/getRoomsForConferenceLocation",
  async (args) => {
    const { id, conference } = args
    let i = 0
    let pages = 1
    let allRooms = []

    while (i < pages) {
      let query = {
        filter: {
          "field_location.id": id,
        },
        page: { offset: i * 50 },
      }

      let response = await fetchWrapper.get(
        "/api/mobile_mind_room/room_base?" + qs.stringify(query)
      )

      if (response.ok) {
        let roomsData = await response.json()
        let newRooms = roomsData.data.map((room) => {
          room.text = room.attributes.field_room_number_name
          return room
        })
        let filteredRooms = newRooms.filter(
          (room) => !room.attributes.field_archive
        )
        let orderedRoomIds = conference.relationships.field_rooms.data.map(
          (room) => room.id
        )
        // Order the rooms the way they are on the conference entity
        const roomsInOrder = orderedRoomIds
          .map((roomId) => {
            return filteredRooms.find((room) => room.id === roomId)
          })
          .filter((room) => room)
        pages = Math.ceil(Number(roomsData.meta.count) / 50)
        allRooms = allRooms.concat(roomsInOrder)
      }
      i++
    }

    return allRooms
  }
)

const today = new Date()
const endDate = new Date(today)

endDate.setDate(endDate.getDate() + 0.04)

export const activeConferenceSlice = createSlice({
  name: "activeConferenceSlice",
  initialState: {
    addOn: null,
    sessionImportSearchResults: {
      data: [],
      fetched: false,
    },
    feedbackSummary: {
      sortBy: "name",
      data: [],
      fetched: false,
    },
    attendanceSummary: {
      data: [],
      isFetching: false,
      totalRecords: 0,
      currentPage: 0,
      totalPages: 1,
      filters: {
        search: "",
        sortOrder: "DESC",
        sortBy: "name",
      },
    },
    tags: [],
    groupData: null,
    id: null,
    drupal_internal__id: null,
    fetched: false,
    currentTab: window.location.href.includes("view") ? "schedule" : "details",
    name: "",
    startDate: moment().endOf("hour").add(1, "minute").format(),
    endDate: moment().endOf("hour").add(1, "minute").add(1, "hours").format(),
    allDay: false,
    description: "",
    type: "In Person Live",
    category: null,
    notes: "",
    meetingLink: "",
    registrationWindow: "unlimited",
    registrationStarts: moment().endOf("hour").add(1, "minute").format(),
    registrationEnds: moment()
      .endOf("hour")
      .add(1, "minute")
      .add(1, "hours")
      .format(),
    eventMax: 0,
    sendReminders: [],
    locations: [],
    personnel: [],
    personnelSearch: "",
    difficulty: "N/A",
    pdCredit: {
      hours: 0,
      minutes: 0,
    },
    preRequisiteCourses: [],
    preRequisiteCoursesSearch: "",
    preRequisiteCoursesResults: [],
    replacementCourses: [],
    replacementCoursesSearch: "",
    replacementCoursesResults: [],
    relatedLP: null,
    relatedLPSearch: "",
    relatedLPResults: [],

    badgeAwarded: null,
    isBadgeSaving: false,
    newBadge: {
      image: null,
      filename: null,
      name: "",
    },
    eventImage: {
      file: null,
      filename: null,
    },
    attendeeMethod: "unselected",
    subGroups: [],
    jobTitles: [],
    attendees: [],
    isSaving: false,
    attachments: [],
    feedbackMethod: "custom",
    feedbackURL: "",
    feedbackFormId: null,
    feedbackRequired: false,
    feedbackIsAnonymous: false,
    attendanceMethod: "join_code",
    joinCode: "",
    inviteeSearch: "",
    invitees: [],
    messages: {
      fetched: false,
      data: [],
    },
    newMessage: "",
    newMessageEmailTarget: "rsvp_only",

    checkInStart: moment().endOf("hour").add(1, "minute").format(),
    checkInEnd: moment().endOf("hour").add(1, "minute").add(1, "hour").format(),
    originalParticipants: [],
    originalAttendeeMethod: "unselected",
    helpfulLinks: "",
    learnerSearch: "",
    allOrgUsers: [],
    orgUsers: [],
    subGroupSearch: "",
    sendPublishEmail: false,
    missingFields: [],
    availableRooms: [],
    conferenceEvents: [],
    isEditingEvent: false,
    isLoadingEvent: false,

    isRoomFormOpen: false,
    listViewFilters: {
      roomId: "all",
      searchQuery: "",
    },
    isAttendeeModalOpen: false,
    isJoinCodeModalOpen: false,
    awardPD: "conference",
    originalAwardPD: null,
    inviteesCurrentPage: 0,
    filterRSVP: "any",
    filterCheckedIn: "any",
    filterGroup: "all",
    requireRSVP: true,
    isFullScreen: false,
    sortBy: "field_rsvp_value",
    sortOrder: "DESC",
    editorLock: null,
    currentEditor: null,
  },
  reducers: {
    startNewConference: (state, action) => {
      //@todo - move these default states into another file
      state.addOn = null
      state.sessionImportSearchResults = {
        data: [],
        fetched: false,
      }
      state.attendanceSummary = {
        data: [],
        isFetching: false,
        totalRecords: 0,
        currentPage: 0,
        totalPages: 1,
        filters: {
          search: "",
          sortOrder: "DESC",
          sortBy: "name",
        },
      }

      state.tags = []
      state.filterRSVP = "any"
      state.filterCheckedIn = "any"
      state.groupData = null
      state.fetched = action.payload
      state.addOnId = null
      state.id = null
      state.attendanceLogId = null
      state.drupal_internal__id = null
      state.currentTab = window.location.href.includes("view")
        ? "schedule"
        : "details"
      state.name = ""
      state.startDate = moment().endOf("hour").add(1, "minute").format()
      state.endDate = moment()
        .endOf("hour")
        .add(1, "minute")
        .add(1, "hours")
        .format()
      state.allDay = false
      state.description = ""
      state.type = "In Person Live"
      state.category = null
      state.notes = ""
      state.meetingLink = ""
      state.registrationStarts = moment()
        .endOf("hour")
        .add(1, "minute")
        .format()
      state.registrationEnds = moment()
        .endOf("hour")
        .add(1, "minute")
        .add(1, "hours")
        .format()
      state.eventMax = 0
      state.sendReminders = ["none"]
      state.sendRegistrationReminders = ["none"]
      state.locations = []
      state.personnel = []
      state.personnelSearch = ""
      state.difficulty = "N/A"
      state.pdCredit = {
        hours: 0,
        minutes: 0,
      }
      state.preRequisiteCourses = []
      state.preRequisiteCoursesSearch = ""
      state.preRequisiteCoursesResults = []
      state.replacementCourses = []
      state.replacementCoursesSearch = ""
      state.replacementCoursesResults = []
      state.relatedLP = null
      state.relatedLPSearch = ""
      state.relatedLPResults = []
      state.badgeAwarded = null
      state.badgeName = ""
      state.eventImage = {
        file: null,
        filename: null,
      }
      state.attendeeMethod = "unselected"
      state.subGroups = []
      state.jobTitles = []
      state.attendees = []
      state.isSaving = false
      state.attachments = []

      state.feedbackMethod = "custom"
      state.feedbackURL = ""
      state.feedbackFormId = null
      state.feedbackRequired = false
      state.feedbackIsAnonymous = false
      state.attendanceMethod = "join_code"
      state.joinCode = ""
      state.inviteeSearch = ""
      state.invitees = []
      state.messages = {
        fetched: false,
        data: [],
      }
      state.newMessage = ""
      state.newMessageEmailTarget = "rsvp_only"
      state.registrationWindow = "unlimited"
      state.checkInStart = moment().endOf("hour").add(1, "minute").format()
      state.checkInEnd = moment()
        .endOf("hour")
        .add(1, "minute")
        .add(1, "hours")
        .format()
      state.originalParticipants = []
      state.originalAttendeeMethod = "unselected"
      state.helpfulLinks = ""
      state.learnerSearch = ""
      state.allOrgUsers = []
      state.orgUsers = []
      state.subGroupSearch = ""
      state.sendPublishEmail = false
      state.pushNotifications = false
      state.missingFields = []
      state.availableRooms = []
      state.conferenceEvents = []

      state.isLoadingEvent = false
      state.isEditingEvent = false
      state.isRoomFormOpen = false
      state.listViewFilters = {
        roomId: "all",
        searchQuery: "",
      }
      state.isAttendeeModalOpen = false
      state.isJoinCodeModalOpen = false
      state.awardPD = "conference"
      state.originalAwardPD = "conference"
      state.inviteesCurrentPage = 0
      state.filterGroup = "all"
      state.requireRSVP = true
      state.isFullScreen = false
      state.sortBy = "field_rsvp_value"
      state.sortOrder = "DESC"
      state.editorLock = null
      state.currentEditor = null
      state.relatedLP = null
      state.locationsRoomsToFetch = 0
      state.locationsRoomsFetched = 0
    },
    setConferenceRelatedLP: (state, action) => {
      state.relatedLP = action.payload
    },
    selectConferenceCourse: (state, action) => {
      const { field, value } = action.payload
      let course = { ...value }
      course.id = value.uuid
      if (!state[field].find((existing) => existing.id === course.id)) {
        state[field] = state[field].concat([course])
      }

      state[field + "Search"] = ""
    },
    deleteConferenceCourse: (state, action) => {
      const { field, value } = action.payload
      state[field] = state[field].filter((course) => course.id !== value.id)
    },
    setIsLoadingEvent: (state, action) => {
      state.isLoadingEvent = action.payload
      state.isSaving = false
    },
    reorderRooms: (state, action) => {
      const { currentOrder, selectedLocation } = action.payload
      // Find the right location in case of multi-location conferences
      state.locations.forEach((location) => {
        if (location.id === selectedLocation) {
          location.rooms = currentOrder
        }
      })
    },
    changeConferenceTab: (state, action) => {
      state.currentTab = action.payload
    },
    clearConferenceBadgeImage: (state, action) => {
      state.newBadge.image = null
      state.newBadge.filename = null
    },
    addNewRoom: (state, action) => {
      state.availableRooms.push(action.payload)
    },
    addConferenceTag: (state, action) => {
      state.tags.push(action.payload)
    },
    deleteConferenceTag: (state, action) => {
      state.tags.splice(action.payload, 1)
    },
    addConferencePersonnel: (state, action) => {
      let personnel = { ...action.payload }
      personnel.role = state.eventRoles[0].id
      personnel.field_user_image_path = personnel.user_picture
      state.personnel.push(personnel)
    },
    removeConferencePersonnel: (state, action) => {
      state.personnel = state.personnel.filter((user) => {
        if (user.user && action.payload.user) {
          return user.user.id !== action.payload.user.id
        } else if (user.uuid && action.payload.uuid) {
          return user.uuid !== action.payload.uuid
        } else {
          return true
        }
      })
    },
    setConferencePersonnelRole: (state, action) => {
      let allPersonnel = [...state.personnel]
      let targetUser = allPersonnel.find(
        (user) => user.id === action.payload.user.id
      )
      targetUser.role = action.payload.role
      state.personnel = allPersonnel
    },
    updateConferenceField: (state, action) => {
      state = updateEventField(state, action)
    },
    addRemoveLocation: (state, action) => {
      const { location, type } = action.payload

      if (type === "add") {
        state.locations.push(location)
      } else {
        state.locations = state.locations.filter(
          (loc) => loc.id !== location.id
        )
      }
    },

    setConferenceImage: (state, action) => {
      state.eventImage.image = action.payload
    },
    setConferenceImageFilename: (state, action) => {
      state.eventImage.filename = action.payload
    },
    removeConferenceImage: (state, action) => {
      state.eventImage = {
        file: null,
        filename: null,
      }
    },
    setIsFullScreen: (state, action) => {
      state.isFullScreen = action.payload
    },
    setConferenceIsFetched: (state, action) => {
      state.fetched = action.payload
    },
    setEventSaving: (state, action) => {
      state.isSaving = action.payload
    },
    setConferenceBadgeName: (state, action) => {
      state.badgeName = action.payload
    },
    addNewBadge: (state, action) => {
      state.badgeAwarded = action.payload
    },
    changeConferenceAttendees: (state, action) => {
      if (action.payload.type === "add") {
        state[action.payload.field].push(action.payload.value)
      } else {
        state[action.payload.field] = state[action.payload.field].filter(
          (item) => item.id !== action.payload.value.id
        )
      }
    },
    addConferenceAttachment: (state, action) => {
      state.attachments.push(action.payload)
    },
    editConferenceEvent: (state, action) => {
      state.isEditingEvent = true
      state.loadEvent = action.payload
    },
    removeConferenceAttachment: (state, action) => {
      state.attachments = state.attachments.filter(
        (file) => file.file !== action.payload.file
      )
    },
    regenerateJoinCode: (state, action) => {
      state.isGeneratingCode = true
    },
    getNewJoinCode: (state, action) => {
      state.joinCode = action.payload.attributes.field_join_code
      state.isGeneratingCode = false
    },
    addSavedMessage: (state, action) => {
      state.newMessage = ""
      state.messages.data.unshift(action.payload)
    },
    removeMessage: (state, action) => {
      state.messages.data = state.messages.data.filter(
        (message) => message.id !== action.payload.id
      )
    },
    setIsEditingEvent: (state, action) => {
      state.isEditingEvent = action.payload
    },
    setConferenceSaving: (state, action) => {
      state.isSaving = action.payload
    },
    draftSaved: (state, action) => {
      state.field_draft = true
      state.addOnId = action.payload.addOn.data.id
      state.id = action.payload.event.id
      state.attendanceLogId = action.payload.logId
    },
    updatedCheckedInAttendees: (state, action) => {
      if (!action.meta.undoCheckIn) {
        state.checkedInAttendees.push(action.payload)
      } else {
        state.checkedInAttendees = state.checkedInAttendees.filter(
          (attendee) => attendee.id !== action.payload.id
        )
      }
    },
    addSavedIds: (state, action) => {
      state.id = action.payload.id
      state.addOnId = action.payload.addOnId
    },
    breakConferenceLock: (state) => {
      state.currentEditor = null
      state.editorLock = null
    },
    addSavedEvent: (state, action) => {
      state.conferenceEvents = state.conferenceEvents.filter(
        (event) => event.id !== action.payload.event.id
      )

      if (action.payload.deleteNew) {
        state.conferenceEvents = state.conferenceEvents.filter(
          (event) => !event.id.includes("new")
        )
      }

      let pushedEvent = { ...action.payload.event }
      pushedEvent.image = action.payload.image

      state.conferenceEvents.push(pushedEvent)
    },
    deleteSessionDraft: (state, action) => {
      state.conferenceEvents = state.conferenceEvents.filter(
        (event) => event.event_uuid !== action.payload.id
      )
    },
    dropConferenceEvent: (state, action) => {
      let targetEvent = state.conferenceEvents.find(
        (event) => event.event_uuid === action.payload.id
      )
      targetEvent.field_event_date_time_value = moment(
        action.payload.startDate
      ).format("YYYY-MM-DDTHH:mm:ss-04:00")
      targetEvent.field_event_date_time_end_value = moment(
        action.payload.endDate
      ).format("YYYY-MM-DDTHH:mm:ss-04:00")
      targetEvent.room_uuid = action.payload.newRoomId
    },
    updateListViewFilters: (state, action) => {
      state.listViewFilters[action.payload.field] = action.payload.value
    },

    setIsSessionArchived: (state, action) => {
      const { id, status } = action.payload
      let targetEvent = state.conferenceEvents.find(
        (event) => event.event_uuid === id
      )
      if (targetEvent) {
        targetEvent.field_archive = status ? "True" : "False"
      }
    },
    updateSessionColor: (state, action) => {
      let targetEvent = state.conferenceEvents.find((event) => {
        return event.event_uuid === action.payload.eventId
      })
      targetEvent.field_hex_color = action.payload.hex
      targetEvent.color = action.payload.hex
    },
    updateInviteeRSVP: (state, action) => {
      state.invitees.forEach((invitee) => {
        if (invitee.user_uuid === action.payload.invitee.user_uuid) {
          invitee.field_rsvp = action.payload.rsvp
        }
      })
    },
    startConferenceFromCalendar: (state, action) => {
      state.startDate = moment(action.payload.startDate)
        .add(10, "hours")
        .format()
      state.endDate = moment(action.payload.startDate).add(11, "hours").format()
    },
  },
  extraReducers: {
    [fetchConferenceFeedbackSummary.fulfilled]: (state, action) => {
      state.feedbackSummary.data = action.payload
      state.feedbackSummary.fetched = true
    },
    [increaseConferenceInviteePage.fulfilled]: (state, action) => {
      state.inviteesLoading = true
      state.inviteesCurrentPage = state.inviteesCurrentPage + 1
    },

    [fetchConferenceMessages.fulfilled]: (state, action) => {
      state.messages.data = action.payload.reverse()
      state.messages.fetched = true
    },
    [updateConferenceInviteeSearch.fulfilled]: (state, action) => {
      state.inviteeSearch = action.payload
    },
    [increaseSummaryPage.pending]: (state, action) => {
      state.attendanceSummary.isFetching = true
      state.attendanceSummary.currentPage =
        state.attendanceSummary.currentPage + 1
    },
    [updateSummaryFilters.pending]: (state, action) => {
      state.attendanceSummary.isFetching = true
      state.attendanceSummary.currentPage = 0
      if (action.meta.arg && action.meta.arg.field) {
        state.attendanceSummary.filters[action.meta.arg.field] =
          action.meta.arg.value
      }
    },
    [getAttendanceSummary.fulfilled]: (state, action) => {
      const { conference_data } = action.payload
      state.attendanceSummary.isFetching = false
      state.attendanceSummary.totalRecords = action.payload.total_records
      state.attendanceSummary.totalPages = Math.ceil(
        action.payload.total_records / 25
      )
      if (!state.attendanceSummary.currentPage) {
        state.attendanceSummary.data = conference_data
      } else {
        state.attendanceSummary.data =
          state.attendanceSummary.data.concat(conference_data)
      }
    },
    [updateConferenceCheckedInFilter.pending]: (state, action) => {
      state.filterCheckedIn = action.meta.arg
      state.inviteesCurrentPage = 0
      state.totalInviteePages = 0
    },
    [updateConferenceGroupFilter.pending]: (state, action) => {
      state.filterGroup = action.meta.arg
      state.inviteesCurrentPage = 0
      state.totalInviteePages = 0
    },
    [updateConferenceUserSearch.fulfilled]: (state, action) => {
      if (action.payload) {
        state.learnerSearchActive = true
      }
      state.orgUsers = []
      state.learnerSearch = action.payload
    },
    [getLearningPaths.pending]: (state, action) => {
      state.relatedLPSearch = action.meta.arg
      state.relatedLPResults = []
    },
    [getLearningPaths.fulfilled]: (state, action) => {
      state.relatedLPResults = action.payload.lp_data
    },
    [getCourses.pending]: (state, action) => {
      const { field, value } = action.meta.arg
      state[field] = value
    },

    [getCourses.fulfilled]: (state, action) => {
      const { field } = action.meta.arg
      if (field === "preRequisiteCoursesSearch") {
        state.preRequisiteCoursesResults = action.payload.courses
      } else {
        state.replacementCoursesResults = action.payload.courses
      }
    },

    [getConferenceUserEvents.pending]: (state, action) => {
      state.inviteesLoading = true
      state.totalInviteePages = 0
    },
    [getConferenceUserEvents.fulfilled]: (state, action) => {
      action.payload.rows.forEach((invitee) => {
        if (!invitee.field_rsvp) {
          invitee.field_rsvp = "None"
        }
      })

      if (state.inviteesCurrentPage) {
        state.invitees = state.invitees.concat(action.payload.rows)
      } else {
        state.invitees = action.payload.rows
      }
      state.totalInviteePages = Math.ceil(
        Number(action.payload.pager.total_items) / 50
      )
      state.inviteesLoading = false
    },
    [getRoomsForConferenceLocation.fulfilled]: (state, action) => {
      state.locationsRoomsFetched += 1
      state.availableRooms = state.availableRooms.concat(action.payload)

      if (state.locationsRoomsFetched === state.locationsRoomsToFetch) {
        state.fetched = true
      }
    },
    [updateConferenceInviteeRSVPFilter.fulfilled]: (state, action) => {
      state.filterRSVP = action.payload
      state.inviteesCurrentPage = 0
      state.totalInviteePages = 0
    },
    [getConferenceUserList.fulfilled]: (state, action) => {
      state.allOrgUsers = state.allOrgUsers.concat(action.payload.rows)
      state.orgUsers = action.payload.rows

      if (!state.learnerSearch && action.payload?.rows) {
        state.orgUsers = action.payload.rows.slice(0, 20)
      }
      state.learnerSearchActive = false
    },
    [fetchActiveConference.pending]: (state) => {
      state.fetched = false
      state.availableRooms = []
    },
    [fetchConferenceRoles.fulfilled]: (state, action) => {
      state.eventRoles = action.payload
    },
    [updateConferenceSortBy.pending]: (state, action) => {
      state.inviteesCurrentPage = 0
    },
    [updateConferenceSortBy.fulfilled]: (state, action) => {
      state.sortBy = action.meta.arg.sortBy
    },
    [updateConferenceSortOrder.fulfilled]: (state, action) => {
      state.sortOrder = action.meta.arg.sortOrder
    },
    [fetchSessionsForConference.pending]: (state, action) => {
      state.sessionsFetched = false
    },
    [fetchSessionsForConference.fulfilled]: (state, action) => {
      state.sessionsFetched = true

      state.conferenceEvents = !action.payload.rows.content
        ? action.payload.rows
        : []

      state.conferenceEvents = _.orderBy(
        state.conferenceEvents,
        (event) => event.field_event_date_time_value
      )
    },
    [updateSessionImportSearch.pending]: (state) => {
      state.sessionImportSearchResults.fetched = false
    },
    [searchSessionsForImport.fulfilled]: (state, action) => {
      state.sessionImportSearchResults.fetched = true
      state.sessionImportSearchResults.data = action.payload
    },

    [fetchActiveConference.fulfilled]: (state, action) => {
      const addOn = action.payload.addOn
      const attributes = action.payload.data[0].attributes

      state.addOn = addOn
      const includedCourses =
        addOn.included &&
        addOn.included.filter(
          (included) => included.type === "course_entity--course_entity"
        )
      const preRequisiteCourseIds =
        addOn.data.relationships.field_prereq_courses.data.map(
          (course) => course.id
        )
      const replacementCourseIds =
        addOn.data.relationships.field_replacement_courses.data.map(
          (course) => course.id
        )
      const badgeAwarded = action.payload.badgeAwarded

      state.editorLock = attributes.field_editor_lock_time
      state.currentEditor =
        action.payload.currentEditor && action.payload.currentEditor[0]

      const relatedLP =
        addOn.included &&
        addOn.included.find(
          (included) => included.type === "learning_path--learning_path"
        )

      if (relatedLP) {
        state.relatedLP = {
          name: relatedLP.attributes.name,
          field_lp_uuid: relatedLP.id,
          field_lp_id: relatedLP.attributes.drupal_internal__id,
        }
      }

      const images =
        action.payload.included &&
        action.payload.included.filter(
          (included) => included.type === "file--image"
        )

      state.category = action.payload.category
      state.pushNotifications =
        attributes.field_notify === "push" ? true : false

      state.requireRSVP =
        action.payload.data[0].attributes.field_rsvp_to_view_sessions

      state.id = action.payload.data[0].id
      state.drupal_internal__id = attributes.drupal_internal__id
      state.name = attributes.name
      state.newBadge.name = attributes.name
      state.field_draft = attributes.field_draft
      state.helpfulLinks = attributes.field_helpful_links
        ? attributes.field_helpful_links.value
        : ""

      state.isOrgLevel = !action.payload.data[0].relationships.field_group.data
        ? true
        : false

      const tags =
        action.payload.included &&
        action.payload.included.filter(
          (included) => included.type === "taxonomy_term--tags"
        )
      if (tags && tags.length) {
        state.tags = tags.map((tag) => {
          return {
            id: tag.id,
            uuid: tag.id,
            text: tag.attributes.name,
          }
        })
      }
      if (action.payload.data[0].relationships.field_group.data) {
        state.groupData = action.payload.data[0].relationships.field_group.data
      }

      if (action.payload.rooms) {
        if (action.payload.data[0].attributes.field_type !== "In Person Live") {
          action.payload.rooms.unshift({
            id: "none",
            text: "Virtual Sessions",
            attributes: {
              name: "Virtual Sessions",
            },
          })
        }
      }

      state.personnel = action.payload.personnel
        ? action.payload.personnel.map((user) => {
            return {
              personnelId: user.id,
              drupal_internal__id: user.attributes.drupal_internal__id,
              existing: true,
              field_bio: user.attributes.field_bio,
              field_first_name: user.attributes.field_first_name,
              field_last_name: user.attributes.field_last_name,
              field_subgroup: user.attributes.field_subgroup,
              field_event_role_name: user.attributes.field_event_role_name,
              field_user_name: user.attributes.field_user_name,
              field_user_image_path: user.attributes.field_user_image_path,
              id: user.relationships.field_user.data.id,
              role:
                user.relationships.field_event_role_term.data &&
                user.relationships.field_event_role_term.data.id,
              uuid: user.relationships.field_user.data.id,
            }
          })
        : []

      let startDate = moment(attributes.field_event_date_time[0].value)
      let endDate = attributes.field_event_date_time[0].end_value

      state.startDate = moment(startDate).format()
      state.endDate = moment(endDate).format()

      if (
        moment(attributes.field_event_date_time[0].end_value).diff(
          moment(attributes.field_event_date_time[0].value),
          "hours"
        ) === 24
      ) {
        state.allDay = true
      }

      state.description =
        attributes.field_description && attributes.field_description.value
          ? attributes.field_description.value
          : ""
      state.type = attributes.field_type

      state.notes =
        attributes.field_event_notes && attributes.field_event_notes.value
          ? attributes.field_event_notes.value
          : ""

      state.locations = action.payload.locations
      state.locationsRoomsToFetch = action.payload.locations.length
      state.locationsRoomsFetched = 0

      state.meetingLink = attributes.field_meeting_link
        ? attributes.field_meeting_link.uri
        : ""
      state.eventMax = attributes.field_capacity

      state.sendReminders = attributes.field_event_reminder.length
        ? attributes.field_event_reminder
        : ["none"]
      state.sendRegistrationReminders = attributes.field_reminder.length
        ? attributes.field_reminder
        : ["none"]

      state.attendanceMethod = addOn.data.attributes.field_attendance_method
        ? addOn.data.attributes.field_attendance_method
        : "join_code"
      state.feedbackURL = addOn.data.attributes.field_feedback_form_url
        ? addOn.data.attributes.field_feedback_form_url
        : ""

      if (action.payload.feedbackForm) {
        state.feedbackFormId = action.payload.feedbackForm?.id
        state.feedbackForm = action.payload.feedbackForm
        state.feedbackMethod = "custom"
      }

      state.feedbackRequired =
        addOn.data.attributes.field_feedback_required_for_cred
      state.feedbackIsAnonymous = addOn.data.attributes.field_anonymous_feedback

      if (
        attributes.field_registration_start &&
        attributes.field_registration_end
      ) {
        state.registrationStarts = moment(
          attributes.field_registration_start
        ).format()
        state.registrationEnds = moment(
          attributes.field_registration_end
        ).format()
        state.registrationWindow = "dateRange"
      }

      if (
        action.payload.attendanceLog &&
        action.payload.attendanceLog.data.attributes.field_check_in_period
      ) {
        state.checkInStart =
          action.payload.attendanceLog.data.attributes.field_check_in_period.value
        state.checkInEnd =
          action.payload.attendanceLog.data.attributes.field_check_in_period.end_value
      }

      state.joinCode =
        action.payload.attendanceLog &&
        action.payload.attendanceLog.data.attributes.field_join_code
      state.attendanceLogId =
        action.payload.attendanceLog && action.payload.attendanceLog.data.id
      state.checkedInAttendees = action.payload.attendanceLog
        ? action.payload.attendanceLog.data.relationships.field_attendees.data
        : []

      state.difficulty = addOn.data.attributes.field_level
        ? addOn.data.attributes.field_level
        : "N/A"

      let minutes = addOn.data.attributes.field_credit
        ? addOn.data.attributes.field_credit
        : 0

      const hours = minutes / 60
      const rhours = Math.floor(hours)
      minutes = (hours - rhours) * 60

      state.pdCredit.hours = rhours
      state.pdCredit.minutes = Math.round(minutes)

      state.preRequisiteCourses = includedCourses
        ? includedCourses.filter((course) =>
            preRequisiteCourseIds.includes(course.id)
          )
        : []
      state.replacementCourses = includedCourses
        ? includedCourses.filter((course) =>
            replacementCourseIds.includes(course.id)
          )
        : []
      state.badgeAwarded = badgeAwarded
      state.badgeName = badgeAwarded && badgeAwarded.name

      state.addOnId = addOn.data.id
      state.field_archive = attributes.field_archive

      state.attachments = []

      // PD credit awarded for...
      if (action.payload.data[0]) {
        let pdAwarded = action.payload.data[0].attributes
          .field_award_credit_for_conferenc
          ? "conference"
          : "sessions"
        state.awardPD = pdAwarded
        state.originalAwardPD = pdAwarded
      }
      // Handle attachments
      if (action.payload.included) {
        action.payload.included.forEach((included) => {
          let isAttachment =
            action.payload.data[0].relationships.field_attachment.data &&
            action.payload.data[0].relationships.field_attachment.data.find(
              (attachment) => attachment.id === included.id
            )
          if (isAttachment) {
            state.attachments.push({
              id: included.id,
              filename: included.attributes.filename,
              extension: included.attributes.filename.split(".").pop(),
              file: included.attributes.uri.url,
            })
          }
        })
      }

      state.attendees = []
      state.subGroups = []
      state.jobTitles = []

      const { field_participants_job_title } = addOn.data.relationships

      // Handle attendees
      const field_participants_users =
        addOn.included?.filter((included) => included.type === "user--user") ??
        []

      if (field_participants_users.length) {
        field_participants_users.forEach((user) => {
          let existing = state.attendees.find((item) => item.id === user.id)
          !existing && state.attendees.push(user)
        })
      }

      if (addOn.data.subGroups.length > 0) {
        state.subGroups = addOn.data.subGroups
      }
      if (field_participants_job_title.data.length) {
        field_participants_job_title.data.forEach((jobTitle) => {
          let existing = state.jobTitles.find((item) => item.id === jobTitle.id)
          !existing && state.jobTitles.push(jobTitle)
        })
      }

      state.originalParticipants = field_participants_users.concat(
        state.subGroups,
        field_participants_job_title.data
      )

      state.attendeeMethod = addOn.data.attributes.field_participants_type
        ? addOn.data.attributes.field_participants_type
        : "unselected"
      state.originalAttendeeMethod = state.attendeeMethod
      state.eventImage = {
        file:
          images &&
          images.find(
            (image) =>
              action.payload.data[0].relationships.field_event_image.data &&
              image.id ===
                action.payload.data[0].relationships.field_event_image.data.id
          ),
      }
    },
  },
})

export const {
  addRemoveLocation,
  changeConferenceTab,
  updateConferenceField,
  startConferenceFromCalendar,
  addConferencePersonnel,
  removeConferencePersonnel,
  setConferencePersonnelRole,
  addConferenceTag,
  deleteConferenceTag,
  setConferenceImage,
  setConferenceImageFilename,
  removeConferenceImage,
  setNewEventAsActive,
  clearConferenceBadgeImage,
  setIsSessionArchived,
  editConferenceEvent,
  setConferenceBadgeName,
  updateListViewFilters,
  setIsFullScreen,
  startNewConference,
  changeConferenceAttendees,
  deleteSessionDraft,
  reorderRooms,
  breakConferenceLock,
  dropConferenceEvent,
  regenerateJoinCode,
  addConferenceAttachment,
  setConferenceSaving,
  setConferenceIsFetched,
  removeConferenceAttachment,
  setIsEditingEvent,
  setIsLoadingEvent,
  setConferenceRelatedLP,
  selectConferenceCourse,
  deleteConferenceCourse,
} = activeConferenceSlice.actions

export default activeConferenceSlice.reducer
